//
import GImageGalleryService from '@/services/api/General/GImageGallery'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-image-gallery',
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        listed: false,
        swap  : false,
        update: false,   
      },
      dialogs: {
        create: false,
        delete: false,
        swap  : false,
        update: false,
      },
      filters: {
        limit: 15,
        page: 1,
      },
      gImageGalleryCreate: {},
      gImageGalleryDelete: {},
      gImageGalleryStored: {},
      gImageGalleryUpdate: {},
      gImageGalleries: {
        data: [],
        last_page: 1,
      },
    }
  },
  methods: {

    /**
     * 
     */
    handleAvatarClick(gImageGallery) {
      GImageGalleryService.shape({ id: 'content' }).query().param('filePath', gImageGallery.filePath).fetch().then(res => {
        window.open(res.request.responseURL, '_blank');
      })
    },

    /**
     * 
     */
    handleCreateReset() {
      this.gImageGalleryCreate = GImageGalleryService.toCreate()
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      await GImageGalleryService.create(this.gImageGalleryCreate).then(this.handleCreateSuccess).catch(this.handleError)
      this.busy.create = false
    },

    /**
     * 
     */
    handleCreateSuccess(payload) {
      this.handleReset()
      this.handleCreateReset()
      GImageGalleryService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleDeleteClick(gImageGallery) {
      this.dialogs.delete   = true
      this.gImageGalleryDelete  = gImageGallery
    },

    /**
     * 
     */
    async handleDeleteSubmit() {
      this.busy.delete = true
      await GImageGalleryService.delete(this.gImageGalleryDelete).then(this.handleDeleteSuccess).catch(this.handleError)
      this.busy.delete = false
    },

    /**
     * 
     */
    handleDeleteSuccess() {
      this.handleReset()
      this.dialogs.delete = false
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.gImageGalleries = await GImageGalleryService.getBy(this.filters)
      //ya que solo llegan tipo none desde el backend se le cambian los hipervinculos con none a "" para condicionar mejor este mismo en el front
      this.gImageGalleries.data.forEach(item => {
        if(item.hipervinculo=="None") item.hipervinculo=""
      }); 
      this.busy.listed = false
    },

    /**
     * 
     */
    handleSwapError(payload) {
      GImageGalleryService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleSwapDownClick(gImageGallery) {
      this.busy.listed = true
      await GImageGalleryService.swapDown(gImageGallery).then(this.handleSwapSuccess).then(this.handleReset).catch(this.handleSwapError)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleSwapSuccess(response) {
      GImageGalleryService.translate({ response }).then(this.$toast.success)
    },

    /**
     * 
     */
    async handleSwapUpClick(gImageGallery) {
      this.busy.listed = true
      await GImageGalleryService.swapUp(gImageGallery).then(this.handleSwapSuccess).then(this.handleReset).catch(this.handleSwapError)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleUpdateClick(gImageGallery) {
      this.dialogs.update   = true
      this.gImageGalleryUpdate  = gImageGallery
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.gImageGalleryUpdate = this.gImageGalleryStored
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      //console.log('imagen a actualizar>',this.gImageGalleryUpdate)
      if(this.gImageGalleryUpdate.image){
        await GImageGalleryService.update(this.gImageGalleryUpdate).then(this.handleUpdateSuccess).catch(this.handleError)
      }else{
        await GImageGalleryService.updateHiperVinculo(this.gImageGalleryUpdate).then(this.handleUpdateSuccess).catch(this.handleError)
      }
      
      this.busy.update = false
      this.dialogs.update   = false
    },

    /**
     * 
     */
    handleUpdateSuccess(payload) {
      GImageGalleryService.translate({ response: payload }).then(this.$toast.success)
      this.handleReset()
    },

    /**
     * 
     */
    handleError(payload) {
      GImageGalleryService.translate(payload).then(this.$toast.error)
    },
  },
  created() {
    this.handleReset()
    this.handleCreateReset()
  },
  props: {

    /**
     * 
     */
    imageRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },
    urlRules: {
      type: Array,
      default: () => [
        RulesService.url,
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.page'() {
      this.handleReset()
    },

    /**
     * 
     */
    'filters.limit'() {
      this.handleReset()
    },
  },
}